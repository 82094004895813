import { Component } from '@angular/core';
import { ScreenService } from 'src/app/services/screen.service';
import { utils } from '../../utils/utils';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-screen',
  templateUrl: './screen.component.html',
  styleUrls: ['./screen.component.scss'],
})
export class ScreenComponent {
  sideNavMenu: object[] = [];
  announcements: string = '';
  lastUpdatedAt: string = '';
  baseIndexRates: any;
  baseIndexRatesLeft: any;
  baseIndexRatesRight: any;
  currentProductListingId: string;
  productListing: object[] = [];
  selectedItemId: string;
  firstsideNavMenu: string;
  currentProductListing: any = [];
  currentAssumptionsForFixedRate: string;
  marqueeSpeed: number = 60;
  isLoading: boolean = true;
  roles: any;
  uid: string;
  isScreen: boolean = true;
  userStatusLocked: boolean = false;
  emailID: string;
  fullName: string;
  isExternalUser: string;
  contactMFAdmin: boolean;
  companyName: string;

  constructor(
    private screenService: ScreenService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.roles = this.authService.getRoles();
    this.uid = this.authService.getUid();
    this.emailID = this.authService.getEmailID();
    this.fullName = this.authService.getFullName();
    this.companyName = this.authService.getCompanyName();
    this.screenService
      .getScreenData(
        this.roles,
        this.uid,
        this.emailID,
        this.fullName,
        this.companyName
      )
      .subscribe({
        next: (data) => {
          if (data?.message === 'contact multifamily admin') {
            this.isScreen = false;
            this.userStatusLocked = false;
            this.contactMFAdmin = true;
            return;
          } else if (data?.message === 'user is locked') {
            this.userStatusLocked = true;
            return;
          } else if (data?.message === 'No data found') {
            this.isScreen = false;
            this.userStatusLocked = false;
            return;
          } else {
            this.userStatusLocked = false;
            this.sideNavMenu = data.list_view_data;
            this.announcements = data.comment_data.cmnt;
            this.lastUpdatedAt = data.comment_data.lastupd_dt;
            this.baseIndexRates =
              data && utils.transformArray(data.rate_base_index);
            this.baseIndexRatesLeft =
              data &&
              utils.transformArray(
                data.rate_base_index.filter((item: any) =>
                  ['13', '14', '15'].includes(item.idx_id)
                )
              );
            this.baseIndexRatesRight =
              data &&
              utils.transformArray(
                data.rate_base_index.filter(
                  (item: any) =>
                    !['13', '14', '15', '1', '2'].includes(item.idx_id)
                )
              );
            this.productListing = data.product_view_data;
            this.currentProductListingId = data.list_view_data[0].vw_id;
            this.currentProductListing = data.product_view_data.filter(
              (product: any) => product.vw_id === this.currentProductListingId
            );
            this.currentAssumptionsForFixedRate =
              (this.currentProductListing[0] &&
                this.currentProductListing[0].relatedcontent) ||
              '';
          }
        },
        error: (err) => console.error('error on screen api:', err),
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  displayContent(selectedItem: any) {
    this.selectedItemId = selectedItem.vw_id;
    this.currentProductListing = this.productListing.filter(
      (product: any) => product.vw_id === this.selectedItemId
    );
    this.currentAssumptionsForFixedRate =
      (this.currentProductListing[0] &&
        this.currentProductListing[0].relatedcontent) ||
      '';
  }
}
